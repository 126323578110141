<template>
  <div class="autumnOctPC">
    <div class="banner">
      <img src="/subject/autumnOct/banner.png" alt="">
    </div>
    <div class="part-1">
      <img src="/subject/autumnOct/tit1.png" alt="">
      <div class="part1-centent">
        <ul>
          <li @mouseover="hoverLi(0)" :class="hoverControl == 0 ? 'hoverLi1' : '' " 
          @mouseleave="noHover">
            <p>
              十月，是我国的收货之季，一片片稻田闪烁着金色的光芒、饱满的硕果正摇摇欲坠，秋菊绽放、桂花飘香，金黄色的落叶在空中飘舞，人们脸上洋溢着幸福的笑容；
            </p>
          </li>
          <li @mouseover="hoverLi(1)" :class="hoverControl == 1 ? 'hoverLi2' : '' "
          @mouseleave="noHover">
            <p>
              十月，也是伦敦金需求旺盛之月，十月的日子总寓意着十全十美，大吉大利，是婚嫁和新店开张的好时节，此时伦敦金制品总是用来作为美好祝福的象征；
            </p>
          </li>
          <li @mouseover="hoverLi(2)" :class="hoverControl == 2 ? 'hoverLi3' : '' "
          @mouseleave="noHover">
            <p>
              十月，还是伦敦金/银产品的投资旺季，人们会在这个丰收之月开始新一年的投资计划，因现货伦敦金具有起点低、双向交易、易上手等特点，所以“炒金”成为越来越多数人的选择；
            </p>
          </li>
        </ul>
      </div>
    </div>
    <div class="part-2">
      <img class="part2tit" src="/subject/autumnOct/tit2.png" alt="">
      <div class="part2-centent">
        <ul>
          <li>
            <img src="/subject/autumnOct/part2start.png" alt="">
            <div>
              <p>
                <span>0</span>
                <span>佣金</span>
              </p>
              <p>
                <span>0</span>
                <span>开户费</span>
              </p>
              <p>
                <span>0.01</span>
                <span>手起投</span>
              </p>
              <p style="opacity:0">
                <span>0.01</span>
                <span>手起投</span>
              </p>
            </div>
          </li>
          <li>
            <img src="/subject/autumnOct/part2time.png" alt="">
            <div>
              <p>
                <span>1</span>
                <span>键下单</span>
              </p>
              <p>
                <span>1</span>
                <span>秒平仓</span>
              </p>
              <p>
                <span>1</span>
                <span>分钟看行情</span>
              </p>
              <p>
                <span>1</span>
                <span>周5 x 24小时可交易</span>
              </p>
            </div>
          </li>
          <li>
            <img src="/subject/autumnOct/part2news.png" alt="">
            <div>
              <p>
                <span></span>
                <span>每天两次解盘</span>
              </p>
              <p>
                <span></span>
                <span>名师在线指导</span>
              </p>
              <p>
                <span></span>
                <span>专业投资建议</span>
              </p>
              <p>
                <span>300万+</span>
                <span>投资者交流社群</span>
              </p>
            </div>
          </li>
        </ul>
        <p class="part2text">
          小本炒伦敦金<br>
          给自己的人生，多一个机会；给自己的家人，多一份交代；
        </p>
        <img @click="openUser" src="/subject/autumnOct/part2Button.png" alt="">
      </div>
    </div>
    <div class="part-3">
      <img class="part3tit" src="/subject/autumnOct/tit3.png" alt="">
      <div class="part3-centent">
        <div class="imgbox">
          <img src="/subject/autumnOct/part3text.png" alt="">
          <img src="/subject/autumnOct/part3gift.png" alt="">
        </div>
        <img @click="openUser" class="part3Button" src="/subject/autumnOct/part3Button.png" alt="">
        <p>名额有限，先到先得</p>
      </div>
    </div>
    <div class="part-4">
      <img class="part4tit" src="/subject/autumnOct/tit4.png" alt="">
      <div class="part4-centent">
        <div class="redPackage">
          <img @click="openUser" src="/subject/autumnOct/part4Button.png" alt="">
        </div>
      </div>
    </div>
    <div class="part-5">
      <img class="part5tit" src="/subject/autumnOct/tit5.png" alt="">
      <div class="part5-centent">
        <p>
          专业权威机构监管<br>
          香港金银业贸易场第081号行员
        </p>
        <div class="imgBox">
          <img src="/subject/autumnOct/part5-1.png" alt="">
          <img src="/subject/autumnOct/part5-2.png" alt="">
        </div>
        <div class="part5text">

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'autumnOctPC',
  data() {
    return {
      hoverControl:'',
    }
  },
  methods:{
    openUser(){
      window.open('https://www.rlcproltd.net/uc/signUp/real');
    },
    hoverLi(attr){
      this.hoverControl = attr;
    },
    noHover(){
      this.hoverControl = 4;
    }
  },
  created(){
    if(document.body.clientWidth<500){
      this.$router.push({
        name:'autumnOctWap',
      })
    }
  }
}
</script>

<style lang="less" scoped>
  img{
    display: block;
  }
  .autumnOctPC{
    width: 100%;
    .banner{
      width: 100%;
      img{
        width: 100%;
      }
    }
    .part-1{
      width: 100%;
      img{
        width: 100%;
      }
      .part1-centent{
        width: 100%;
        height: 1060px;
        background: url('/subject/autumnOct/background1.jpg') no-repeat;
        background-size: cover;
        ul{
          width: 1328px;
          height: auto;
          margin: 0 auto;
          box-sizing: border-box;
          padding-top: 60px;
          li{
            width: 100%;
            height: 280px;
            background: url('/subject/autumnOct/part-1-1.1.png') no-repeat;
            background-size: cover;
            display: flex;
            p{
              width: 580px;
              font-size: 22px;
              color: #a86e4f;
              text-align: justify;
              margin-right: 40px;
              line-height: 40px;
            }
          }
          li:nth-child(1){
            justify-content: flex-end;
            align-items: center;
          }
          li:nth-child(2){
            background: url('/subject/autumnOct/part-1-2.1.png') no-repeat;
            background-size: cover;
            margin: 50px 0;
            justify-content: flex-start;
            align-items: center;
            p{
              margin-right: 0px;
              margin-left: 40px;
            }
          }
          li:nth-child(3){
            background: url('/subject/autumnOct/part-1-3.1.png') no-repeat;
            background-size: cover;
            justify-content: flex-end;
            align-items: center;
          }
          .hoverLi1{
            background: url('/subject/autumnOct/part-1-1.2.png') no-repeat;
            background-size: cover;
            p{
              color: #ffffff;
            }
          }
          .hoverLi2{
            background: url('/subject/autumnOct/part-1-2.2.png') no-repeat !important;
            background-size: cover;
            p{
              color: #ffffff;
            }
          }
          .hoverLi3{
            background: url('/subject/autumnOct/part-1-3.2.png') no-repeat !important;
            background-size: cover;
            p{
              color: #ffffff;
            }
          }
        }
      }
    }
    .part-2{
      width: 100%;
      .part2tit{
        width: 100%;
      }
      .part2-centent{
        width: 100%;
        height: 985px;
        background: url('/subject/autumnOct/background2.jpg') no-repeat;
        background-size: cover;
        box-sizing: border-box;
        padding-top: 80px;
        ul{
          width: 1330px;
          height: 640px;
          margin: 0 auto;
          display: flex;
          // justify-content: center;
          // align-items: center;
          li{
            width: 800px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            img{
              width: 240px;
              margin: 0;
              margin-bottom: 45px;
            }
            div{
              width: 100%;
              box-sizing: border-box;
              p{
                line-height: 60px;
                box-sizing: border-box;
                padding-left: 70px;
                span:nth-child(1){
                  font-size: 44px;
                  color: #e34a3f;
                }
                span:nth-child(2){
                  font-size: 27px;
                  font-weight: 510;
                  color: #a86e4f;
                }
              }
              p:last-child{
                width: 410px;
              }
            }
          }
          li:nth-child(2){
            margin: 0 50px 0 50px;
          }
        }
        .part2text{
          text-align: center;
          color: #a86e4f;
          font-size: 22px;
          line-height: 40px;
        }
        img{
          margin: 40px auto 0;
          cursor: pointer;
        }
      }
    }
    .part-3{
      width: 100%;
      .part3tit{
        width: 100%;
      }
      .part3-centent{
        width: 100%;
        height: 850px;
        background: url('/subject/autumnOct/background3.jpg') no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        padding-top: 115px;
        .imgbox{
          display: flex;
          justify-content: center;
          align-items: center;
          img{
            margin: 0 60px;
          }
        }
        .part3Button{
          width: 360px;
          margin-top: 150px;
          cursor: pointer;
        }
        p{
          color:#a86e4f;
          font-size: 22px;
          margin-top: 30px;
        }
      }
    }
    .part-4{
      width: 100%;
      .part4tit{
        width: 100%;
      }
      .part4-centent{
        width: 100%;
        height: 855px;
        background: url('/subject/autumnOct/background4.jpg') no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        .redPackage{
          width: 894px;
          height: 635px;
          background: url('/subject/autumnOct/part4Red.png') no-repeat;
          background-size: cover;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          box-sizing: border-box;
          padding-bottom: 150px;
          img{
            cursor: pointer;
          }
        }
      }
    }
    .part-5{
      width: 100%;
      .part5tit{
        width: 100%;
      }
      .part5-centent{
        width: 100%;
        height: 1349px;
        background: url('/subject/autumnOct/background5.jpg') no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        padding-top: 55px;
        p{
          color:#a86e4f;
          font-size: 22px;
          text-align: center;
          line-height: 40px;
        }
        .imgBox{
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 50px auto 60px;
          img{
            margin: 0 25px;
          }
        }
        .part5text{
          width: 1328px;
          height: 462px;
          background: url('/subject/autumnOct/part5-cc.png') no-repeat;
          background-size: cover;
        }
      }
    }
  }
</style>